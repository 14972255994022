
import {Component, Vue} from 'vue-property-decorator';
import {progress} from '@/infrastructure/script/Progress';
import api from '@/infrastructure/api/API';
import {
  EmptyInquiry, EmptyInquiryAnswerRegisterRequest,
  Inquiry,
  InquiryAnswerRegisterRequest,
  InquiryGetRequest,
} from '@/types/inquiry/Inquiry';
import {InquiryType} from '@/types/inquiry/InquiryType';
import {InquiryAnswerRegisterChecker} from '@/validator/inquiry/InquiryAnswerRegisterChecker';
import {loginModule} from '@/stores/authentication/Account';
import moment from 'moment';
import {InquiryStatus} from '@/types/inquiry/InquiryStatus';

@Component
export default class InquiryDetailForm extends Vue {
  // data
  inquiry: Inquiry = EmptyInquiry;
  request: InquiryGetRequest | null = null;

  answerForm: InquiryAnswerRegisterRequest = {...this.answerRequest};
  messages: string[] = [];

  // computed
    get assessorNumber(): string {
      return loginModule.assessorNumber;
    }

  get inquiryType(): string {
    if (this.inquiry?.type === InquiryType.ToSystem) {
      return 'システムに関するお問い合わせ';
    } else if (this.inquiry?.type === InquiryType.ToOffice) {
      return 'その他お問い合わせ';
    }
    return 'JAAIからの問い合わせ';
  }

  get answerRequest(): InquiryAnswerRegisterRequest {
    if (sessionStorage.hasOwnProperty('inquiry-detail-form')) {
      return JSON.parse(sessionStorage.getItem('inquiry-detail-form') as string);
    }
    return EmptyInquiryAnswerRegisterRequest;
  }

  get hasError(): boolean {
    return this.messages.length > 0;
  }

  get isCompletedOrInquiry(): boolean {
    return this.inquiry.status === InquiryStatus.Answered ||
        this.inquiry.status === InquiryStatus.Completed ||
        this.inquiry.status === InquiryStatus.Inquiry;
  }

  // methods
  async initialize(): Promise<void> {
    await this.getDetail();
  }

  async getDetail(): Promise<void> {
    const getInquiry = async (): Promise<void> => {
      if (!this.request) {
        return;
      }
      await api.getInquiry(this.request)
          .then((response: any) => {
            this.inquiry = response;
          });
    };

    await progress(getInquiry);
  }

  confirm(): void {
    this.messages = [];
    this.answerForm.title = this.inquiry.title;

    const validator = new InquiryAnswerRegisterChecker(this.answerForm);
    if (validator.hasErrors) {
      this.messages = validator.getMessages;
      return;
    }

    this.answerForm.answersAt = moment().toDate();
    this.answerForm.inquirySeq = this.inquiry.seq;

    sessionStorage.setItem('inquiry-detail-form', JSON.stringify(this.answerForm));

    this.$router.push({path: `/inquiry/${this.inquiry.seq}/confirm`});
  }


  toInquiry(): void {
    this.$router.push({path: '/inquiry'}).catch(() => {
      // @ts-ignore block is empty.
    });
  }

  // lifecycle hooks
  created(): void {
    this.request = {
      // @ts-ignore
      inquirySeq: this.$route.params.seq as number,
    };
    this.initialize();
  }
}
